import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";

import marked from "marked";
import kebabCase from "lodash.kebabcase";

import {
  Bio,
  Layout,
  SEO,
  PreviousNextArticle,
  CommentsBlock,
  Share,
} from "../components";
import { ArticlePage, TagButton } from "../components/styled";

interface IMarkdownRemark {
  excerpt: string;
  frontmatter: {
    date: string;
    description: string;
    image: any;
    imageDescription: string;
    tags: string[];
    title: string;
  };
  html: string;
  id: string;
}

export interface IOtherArticle {
  fields: {
    slug: string;
  };
  frontmatter: {
    tags: string[];
    title: string;
  };
}

interface IPageContext {
  next?: IOtherArticle;
  previous?: IOtherArticle;
}

interface IProps {
  location: Location;
  data: {
    markdownRemark: IMarkdownRemark;
    site: {
      siteMetadata: {
        twitterHandle: string;
      };
    };
  };
  pageContext: IPageContext;
}

const BlogPostTemplate: React.FC<IProps> = ({
  data,
  pageContext,
  location,
}) => {
  const post = data.markdownRemark;
  const { twitterHandle } = data.site.siteMetadata;
  const { previous, next } = pageContext;
  const {
    title,
    description,
    date,
    image,
    imageDescription,
    tags = [],
  } = post.frontmatter;

  const postImage = image && image.publicURL ? image.publicURL : null;

  return (
    <Layout>
      <div>
        <SEO
          title={title}
          description={description || post.excerpt}
          keywords={tags}
          imageURL={postImage}
          location={location}
          datePublished={date}
        />
        <ArticlePage>
          <Bio />
          <h1 className="title">{title}</h1>
          <h2 className="sub-title">{description}</h2>
          <small className="article-date">{date}</small>
          <figure>
            {image && <Img sizes={image.childImageSharp.sizes} />}
            <figcaption
              dangerouslySetInnerHTML={{
                __html: marked.inlineLexer(imageDescription, []) || "",
              }}
            />
          </figure>
          <div
            className="article"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <div
            css={css`
              margin: 24px 0;
            `}
          >
            {tags.length > 0 &&
              tags.map((tag) => (
                <TagButton to={`/tags/${kebabCase(tag)}/`} key={tag}>
                  {tag}
                </TagButton>
              ))}
          </div>
          <hr />
          <Share
            socialConfig={{
              twitterHandle,
              config: {
                url: location.href,
                title,
              },
            }}
            tags={tags}
          />
        </ArticlePage>

        <PreviousNextArticle previous={previous} next={next} />

        <CommentsBlock location={location} />
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        twitterHandle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        image {
          publicURL
          childImageSharp {
            sizes(maxWidth: 2000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        imageDescription
      }
    }
  }
`;
